<template>
<div id="users">
    <v-app-bar flat color="transparent" class="px-1 mt-2" height="64" min-height="64">
        <v-breadcrumbs :items="items" class="pl-2 font-weight-medium body-1">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-text-field hide-details single-line prepend-inner-icon="mdi-magnify" placeholder="Escribe para buscar..." flat solo background-color="white" dense v-model="search"></v-text-field>
        <v-btn depressed color="primary darken-1" class="ml-3 text-none green--text text--lighten-5" to="/usuarios/nuevo">
            <span class="font-weight-bold">Nuevo</span>
            <v-icon color="green lighten-5" small right>mdi-plus</v-icon>
        </v-btn>
    </v-app-bar>
    <v-container fluid class="pt-0 px-5">
        <v-data-table :search="search" fixed-header @click:row="handleClick" :height="height" :headers="headers" :items="users" :items-per-page="20" class="white" :header-props="{
                'sort-by-text': 'Ordenar por'
            }" no-results-text="No se encontraron resultados" no-data-text="No hay información para mostrar"
            :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text':'Filas por página',
            'pageText': '{0}-{1} de {2}',
            'items-per-page-all-text': 'Todas',
            'items-per-page-options': [
                20, 30, 45, -1
            ]
            }">
            <template v-slot:[`item.role`]="{ item }">
                <v-chip color="primary darken-2" class="font-weight-bold" v-if="item.role == 'SUPERADMIN'" small label>
                    Super administrador
                </v-chip>
                <v-chip color="primary darken-3" class="font-weight-bold" v-if="item.role == 'ADMIN'" small label>
                    Administrador
                </v-chip>
                <v-chip color="primary darken-4" class="font-weight-bold" v-if ="item.role == 'USER'" small label>
                    Colaborador
                </v-chip>
            </template>
        </v-data-table>
        <transition name="slide-fade">
            <router-view name="usuarios" :height="height" :key="$route.path"/>
        </transition>
    </v-container>
</div>
</template>

<script>
export default {
    components: {},
    name: 'usuarios',
    data: () => ({
        height: 0,
        search: null,
        headers: [
            { text: 'Nombre', value: 'name' },
            { text: 'Correo electrónico', value: 'email' },
            { text: 'Tipo de usuario', value: 'role' },
        ],
        items: [{
                text: 'Inicio',
                disabled: false,
                href: '/',
            },
            {
                text: 'Mieles',
                disabled: true,
                href: '',
            },
        ],
    }),
    methods: {
        handleClick(e) {
            this.$router.push({ name: 'edit-usuarios', params: { id: e._id } });
        },
        calculateHeight() {
            let paddings = 90;
            let appBar = 64;
            let divider = 1;
            let viewHeight = window.innerHeight;
            this.height = viewHeight - appBar - divider - paddings;
        },
    },
    computed: {
        users() {
            return this.$store.getters['usuario/GET_USUARIOS'];
        }
    },
    created() {
        window.addEventListener("resize", this.calculateHeight);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.calculateHeight);
    },
    mounted() {
        this.calculateHeight();
    }
}
</script>
